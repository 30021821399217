import React from "react";
import { graphql } from "gatsby";
import {Helmet} from "react-helmet"
import Header from "../components/uielements/header/header";
import Cover from "../components/project/cover";
import ProjectPreview from "../components/project/preview";
import PreviewContainer from "../components/project/previewContainer";
import Footer from "../components/footer/footer";

export default function Category({ data: {
    allFile: { edges },
    markdownRemark: { frontmatter }
} }) {
    const { category: currentCategory } = frontmatter;
    const Projects = edges
        .filter(edge => {
            return edge.node.childMarkdownRemark.frontmatter.category === currentCategory
        })
        .map(edge => {
            return (
                <ProjectPreview
                    cover={edge.node.childMarkdownRemark.frontmatter.cover}
                    title={edge.node.childMarkdownRemark.frontmatter.title}
                    path={edge.node.childMarkdownRemark.frontmatter.path} />
            )
        })
    return (
        <>
            <Helmet>
              <title>{frontmatter.title}</title>
              <meta name="description" content={frontmatter.description}/>
            </Helmet>
            <Header />
            <Cover
                title={frontmatter.title}
                cover={frontmatter.cover}
                height={'70vh'}
            />
            <PreviewContainer>
                {Projects}
            </PreviewContainer>
            <Footer />
        </>
    )
}

export const pageQuery = graphql`
query($path: String!) {
    allFile(filter: {relativeDirectory: {eq: "projects"}}) {
        edges {
          node {
            childMarkdownRemark {
              frontmatter {
                category
                cover
                images
                path
                title
                description
              }
            }
          }
        }
      },
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        category
        path
        title
        cover
        images
      }
    }
  }
`